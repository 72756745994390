import { defineStore } from 'pinia'

import { type Product, type ProductVariant } from '@/types'

export interface WishlistStoreItem {  
  /**
   * 
   * Product Variant unique ID
   * 
   */
  id: string
  /**
   * 
   * The product
   * 
   */
  product: Product
  /**
   * 
   * The product variant
   * 
   */
  variant: ProductVariant
  /**
   * 
   * The total number of the product added in the cart
   * 
   */
  quantity: number
}

export const useWishlistStore = defineStore({
  id: 'wishlist',
  state: () => ({
    items: [] as WishlistStoreItem[],
    total: 0
  }),
  getters: {
    getAllItems: (state) => {
      return state.items
    }
  },

  actions: {
    addProductToWishlist(id: string, product: Product, variant: ProductVariant, quantity: number) {
      const inWishlist = this.items.find((item: WishlistStoreItem) => item.id === id)

      if (!inWishlist) {
        // Add the item to the cart:
        this.items.push({
          id: id,
          product: product,
          variant: variant,
          quantity: quantity
        })
      }
    },
    removeProductFromWishlist(id: string, product: Product, variant: ProductVariant, quantity: number ) {
      const wishlistStoreItem: WishlistStoreItem = this.items.find((item: WishlistStoreItem) => item.id === id) as WishlistStoreItem

      if (wishlistStoreItem.quantity === quantity) {
        // Remove the item from the cart:
        this.items = this.items.filter((item: WishlistStoreItem) => item.id !== id)
      } 

      if (wishlistStoreItem.quantity > quantity) {
        // Just decrement the quantity of the item:
        this.items = this.items.map((item: WishlistStoreItem) => {
          if (item.id === variant.id) {
            item.quantity -= quantity
          }
          return item
        })
      }
    },
    updateProductInWishlist(id: string, variant: ProductVariant, quantity: number) {
      const wishlistStoreItem: WishlistStoreItem = this.items.find((item: WishlistStoreItem) => item.id === id) as WishlistStoreItem
      
      if (wishlistStoreItem.quantity === quantity) {
        // Remove the item from the cart:
        this.items = this.items.filter((item: WishlistStoreItem) => item.id !== id)
      }

      // Just decrement the quantity of the item:
      this.items = this.items.map((item: WishlistStoreItem) => {
        if (item.id === variant.id) {
          item.quantity = quantity
        }
        return item
      })
    },
    syncWishlistItemsFromLocalStorage(products: WishlistStoreItem[]) {
      this.items = products
    },
    emptyWishlist() {
      this.items = []
    },
    resetWishlist() {
      this.items = [] as WishlistStoreItem[]
      this.total = 0
    }
  }
})