import { defineStore } from 'pinia'

import { type Product, type ProductVariant } from '@/types'

import { type Cart } from '@/types/cart'

export interface CartStoreItem {  
  /**
   * 
   * Product Variant unique ID
   * 
   */
  id: string
  /**
   * 
   * The product
   * 
   */
  product: Product
  /**
   * 
   * The product variant
   * 
   */
  variant: ProductVariant
  /**
   * 
   * The total number of the product added in the cart
   * 
   */
  quantity: number
}

export const useCartStore = defineStore({
  id: 'cart',
  state: () => ({
    cart: {} as Cart,
    items: [] as CartStoreItem[],
    total: 0
  }),
  getters: {
    getAllItems: (state) => {
      return state.items
    },
    getTotalProducts: (state) => {
      let total = 0

      state.items.forEach((item: CartStoreItem) => {
        total += item.quantity
      })

      return total
    },
    getProductVariantQuantityById: (state) => {
      return (productVariantId: string) => {
        const productVariant = state.items.find((item: CartStoreItem) => item.variant.id === productVariantId)

        if (productVariant) {
          return productVariant.quantity
        }

        return 0
      }
    }
  },

  actions: {
    addProductToCart(id: string, product: Product, variant: ProductVariant, quantity: number) {
      const inCart = this.items.find((item: CartStoreItem) => item.id === id)

      if (!inCart) {
        // Add the item to the cart:
        this.items.push({
          id: id,
          product: product,
          variant: variant,
          quantity: quantity
        })
      } else {
        // Just increment the quantity of the item:
        this.items = this.items.map((item: CartStoreItem) => {
          if (item.id === variant.id) {
            item.quantity += quantity
          }
          return item
        })
      }
    },
    removeProductFromCart(id: string, product: Product, variant: ProductVariant, quantity: number ) {
      const cartStoreItem: CartStoreItem = this.items.find((item: CartStoreItem) => item.id === id) as CartStoreItem

      if (cartStoreItem.quantity === quantity) {
        // Remove the item from the cart:
        this.items = this.items.filter((item: CartStoreItem) => item.id !== id)
      } 

      if (cartStoreItem.quantity > quantity) {
        // Just decrement the quantity of the item:
        this.items = this.items.map((item: CartStoreItem) => {
          if (item.id === variant.id) {
            item.quantity -= quantity
          }
          return item
        })
      }
    },
    updateProductInCart(id: string, variant: ProductVariant, quantity: number) {
      const cartStoreItem: CartStoreItem = this.items.find((item: CartStoreItem) => item.id === id) as CartStoreItem
      
      if (cartStoreItem.quantity === quantity) {
        // Remove the item from the cart:
        this.items = this.items.filter((item: CartStoreItem) => item.id !== id)
      }

      // Just decrement the quantity of the item:
      this.items = this.items.map((item: CartStoreItem) => {
        if (item.id === variant.id) {
          item.quantity = quantity
        }
        return item
      })
    },
    setCartTotal(total: number) {
      this.total = total
    },
    syncCart(cart: Cart) {
      this.cart = cart
    },
    syncCartItemsFromLocalStorage(products: CartStoreItem[]) {
      this.items = products
    },
    emptyCart() {
      this.items = []
    },
    resetCart() {
      this.cart = {} as Cart
      this.items = [] as CartStoreItem[]
      this.total = 0
    }
  }
})